$primary: #1c1c1c !default; $secondary: #f9fcff !default; $tertiary: #1d6d6d !default; $quaternary: #cb3727 !default; $header_background: #cb3727 !default; $header_primary: #ffffff !default; $highlight: #e6cb37 !default; $danger: #d05454 !default; $success: #71bd9f !default; $love: #c16ad7 !default; $selected: #d2edf3 !default; $hover: #d2edf3 !default;  $public_image_path: "https://ghdev.cnvc.org/images";  @import "common/foundation/variables"; @import "common/foundation/mixins"; div.poll-outer {
  div.poll {
    margin: 1em 0;
    border: 1px solid var(--primary-low);
    display: grid;
    grid-template-areas: "poll" "info" "buttons";
    @include breakpoint("mobile-extra-large", min-width) {
      grid-template-columns: 1fr 10em;
      grid-template-areas: "poll info" "buttons buttons";
    }

    ul,
    ol {
      margin: 0;
      padding: 0;
      list-style: none;
      display: inline-block;
      width: 100%;
    }

    li[data-poll-option-id] {
      color: var(--primary);
      padding: 0.5em 0;
      word-break: break-word;

      button {
        background-color: transparent;
        border: none;
        text-align: left;
        padding-left: 23px;
        text-indent: -23px;
      }
    }

    .ranked-choice-poll-options {
      display: flex;
      flex-direction: column;
      gap: 0.5em;
      padding: 0.5em;

      .ranked-choice-poll-option {
        display: inline-flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 0.5em;
        padding: 0.5em;
      }
    }

    .tabs {
      display: none;
    }

    .discourse-poll-ranked_choice-results {
      .tabs {
        &.nav-items {
          display: flex;
          gap: 0.5em;

          .nav-btn {
            background-color: var(--secondary);
            border: none;
            color: var(--primary-medium);
          }

          .active {
            .nav-btn {
              color: var(--primary);
            }
          }
        }
      }

      .rank {
        display: inline-block;
        min-width: 20px;
      }

      .poll-results-ranked_choice-subtitle-rounds {
        margin: 0.25em 0 0.67rem;
      }
    }

    img {
      // Hacky way to stop images without width/height
      // from causing abrupt unintended scrolling
      &:not([width], .emoji),
      &:not([height], .emoji) {
        width: 200px !important;
        height: 200px !important;
        object-fit: contain;
      }
    }

    .poll-info {
      box-sizing: border-box;
      grid-area: info;
      display: flex;
      line-height: var(--line-height-medium);
      color: var(--primary-medium);

      @include breakpoint("mobile-extra-large") {
        border-top: 1px solid var(--primary-low);
        flex-direction: row-reverse;

        &_counts,
        &_instructions {
          padding: 1em;
        }
      }

      @include breakpoint("mobile-extra-large", min-width) {
        gap: 0 1em;
        padding: 1em;
        border-left: 1px solid var(--primary-low);
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      &_counts {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        gap: 0.25em 0;

        @include breakpoint("mobile-extra-large", min-width) {
          justify-content: center;
        }

        @include breakpoint("mobile-extra-large") {
          flex: 1 1 auto;
        }

        &-count {
          gap: 0.25em;
          line-height: 1;
          white-space: nowrap;
          text-align: left;

          .info-label,
          .info-number {
            display: inline;
            margin-right: 0.25em;
            text-align: center;
          }

          @include breakpoint("mobile-extra-large") {
            &:not(:last-child) {
              margin-right: 0.75em;
            }
          }

          @include breakpoint("mobile-extra-large", min-width) {
            &:not(:last-child) {
              margin-bottom: 0.25em;
            }
            display: flex;
            flex-direction: column;
            align-items: center;

            .info-label,
            .info-number {
              margin: 0;
            }

            + .poll-info_counts-count {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              justify-content: center;
              min-width: 0;
              gap: 0 0.33em;
              margin: 0.5em;

              .info-number,
              .info-label {
                font-size: var(--font-up-1);
                min-width: 0;
                white-space: normal;
                line-height: var(--line-height-medium);
              }
            }
          }
        }
        @include breakpoint("mobile-extra-large", min-width) {
          + .poll-info_instructions:not(:empty) {
            margin-top: 1.25em;
          }
        }
      }

      &_instructions {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-self: start;

        &:empty {
          display: none;
        }

        @include breakpoint("mobile-extra-large", min-width) {
          padding-top: 1.25em;

          &:not(:empty) {
            border-top: 1px solid var(--primary-low);
          }
        }

        @include breakpoint("mobile-extra-large") {
          padding-right: 1em;
          height: 100%;
          flex: 1 1 auto;

          &:not(:empty) {
            border-right: 1px solid var(--primary-low);
          }
        }

        li {
          display: flex;
          gap: 0.5em;

          &:not(:last-child) {
            margin-bottom: 0.5em;
          }

          @include breakpoint("mobile-extra-large", min-width) {
            &:not(:last-child) {
              margin-bottom: 1em;
            }

            &:not(:first-child:last-child) {
              // only applied when there are multiple items
              .d-icon {
                width: 15%;
              }

              span {
                width: 85%;
              }
            }

            &:first-child:last-child {
              // when there's a single item, it looks better centered
              display: inline;
              text-align: center;
            }
          }
        }

        .d-icon {
          font-size: var(--font-down-1);
          margin-top: 0.2em;
        }
      }

      .info-text {
        margin: 0.25em 0;
        display: block;
      }

      @include breakpoint("mobile-extra-large", min-width) {
        .info-label {
          font-size: var(--font-up-2);
        }

        .info-number {
          font-size: var(--font-up-6);
        }
      }
    }

    .poll-container {
      box-sizing: border-box;
      grid-area: poll;
      padding: 1em;
      width: 100%;
      overflow: visible;
      align-self: center;

      li {
        cursor: pointer;
        font-size: var(--font-up-1);
      }

      .poll-results-number-rating {
        font-size: var(--font-up-5);
      }
    }

    .poll-title {
      border-bottom: 1px solid var(--primary-low);
      margin-bottom: 0.5em;
      padding-bottom: 0.5em;
    }

    .poll-buttons {
      box-sizing: border-box;
      grid-area: buttons;
      display: flex;
      flex-wrap: wrap;
      gap: 0.5em;
      width: 100%;
      padding: 1em;
      border-top: 1px solid var(--primary-low);

      button {
        white-space: nowrap;
        align-self: start;

        .d-button-label {
          @include ellipsis;
        }

        @include breakpoint("tablet") {
          flex: 1 1 0;

          &:first-child:last-child {
            // if there's only one button,
            // don't expand the width
            flex: 0 1 auto;
          }

          &.toggle-results:first-child {
            // don't expand the back button
            flex: 0 1 auto;
            margin-right: auto;
          }
        }

        @include breakpoint("mobile-large") {
          &:first-child:last-child,
          &.cast-votes {
            // ok to expand button width on smaller screens
            flex: 1 1 100%;
          }
        }
      }

      &:empty {
        display: none;
      }
    }

    .poll-voters:not(:empty) {
      min-height: 30px;
      margin-bottom: 0.25em;

      li {
        display: inline;
      }
    }

    .poll-voters-toggle-expand {
      margin-top: 0.25em;
      width: 100%;
      text-align: center;

      .spinner {
        margin-top: 0.25em;
      }
    }

    .poll-buttons-dropdown,
    .export-results,
    .toggle-status,
    .show-breakdown,
    .show-tally,
    .show-percentage {
      // we want these controls to be separated
      // from voting controls
      margin-left: auto;
    }

    .results {
      > li {
        cursor: default;
        padding: 0.25em 0;

        &:last-child {
          padding-bottom: 0;
        }
      }

      .option {
        p {
          margin: 0;
        }
      }

      .percentage,
      .absolute {
        float: right;
        color: var(--primary-medium);
        margin-left: 0.25em;
      }

      .bar-back {
        background: var(--primary-low);
      }

      .bar {
        height: 0.75em;
        background: var(--primary-medium);
      }

      .chosen .bar {
        background: var(--tertiary);
      }
    }

    .pie-chart-legends {
      justify-content: center;
      text-align: center;
      margin-top: 0.5em;

      .legend {
        align-items: center;
        cursor: pointer;
        display: inline-flex;
        flex-direction: row;
        margin-left: 1em;
        font-size: var(--font-down-0);

        .swatch {
          margin-right: 0.5em;
          display: inline-block;
          height: 16px;
          width: 16px;
        }
      }
    }

    .poll-grouped-pies-controls {
      display: flex;
      justify-content: space-between;
    }

    .poll-results-chart {
      overflow-y: auto;
      overflow-x: hidden;
    }
  }

  &[data-poll-type="number"] {
    li[data-poll-option-id] {
      display: inline-block;
      width: 3.25em;
      margin-right: 0.25em;
    }
  }
}

.d-editor-preview {
  .poll {
    ul {
      list-style-type: none;
      padding: 0;
    }

    li[data-poll-option-id]::before {
      position: relative;
      vertical-align: baseline;
      border: 2px solid var(--primary);
      border-radius: 50%;
      display: inline-block;
      margin-right: 0.5em;
      width: 12px;
      height: 12px;
      content: "";
    }

    &[data-poll-type="multiple"],
    &[data-poll-type="ranked_choice"] {
      [data-poll-option-id] {
        display: flex;
        align-items: center;
      }
    }

    &[data-poll-type="multiple"] {
      li[data-poll-option-id]::before {
        border-radius: 3px;
      }
    }

    &[data-poll-type="ranked_choice"] {
      li[data-poll-option-id] {
        position: relative;

        &::before {
          mask-image: svg-uri(
            '<svg width="0.75em" height="0.75em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/></svg>'
          );
          z-index: 1;
          width: 0.75em;
          margin-right: 0.75em;
          left: 0.2em;
          background: var(--primary-high);
          border-radius: var(--d-button-border-radius);
          border: none;
        }

        &::after {
          content: "";
          position: absolute;
          height: 1.125em;
          width: 1.125em;
          background: var(--primary-low);
          border-radius: var(--d-button-border-radius);
        }
      }
    }
  }
}

// hides 0 vote count in crawler and print view
body.crawler {
  .poll {
    .poll-info,
    .poll-button {
      display: none;
    }
  }
}
.poll-ui-builder-modal {
  .show-advanced {
    margin-left: auto;
    margin-right: 0;
  }
}

.poll-ui-builder {
  label {
    font-weight: bold;
  }

  .input-group {
    margin-bottom: 15px;

    input[type="number"],
    input[type="text"],
    input[type="time"],
    .combo-box,
    .multi-select,
    .select-kit-header,
    .date-picker-wrapper {
      width: 100%;
    }

    .date-picker-wrapper {
      margin-right: 5px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .poll-public .d-toggle-switch__label {
    margin-bottom: 0;
  }

  .radio-group {
    display: inline-block;
    margin-right: 30px;

    input {
      vertical-align: top;
    }

    label {
      display: inline-block;
    }
  }

  .poll-type {
    .poll-type-value {
      font-size: var(--font-down-1);
    }
    padding: 0;
    margin-top: 0;
    border: none;
  }

  .poll-options {
    margin-bottom: 15px;

    .poll-option-value {
      align-items: flex-start;
      display: flex;
      justify-content: space-between;
      margin-bottom: 0;

      button {
        margin-left: 0.5rem;
      }
    }

    .poll-option-controls {
      margin-top: 10px;
    }

    .tip {
      display: inline-block;
      vertical-align: bottom;
    }
  }

  .tip {
    display: block;
    font-size: var(--font-down-1);
    margin: -0.5em 0 0.5em;
  }

  .d-editor-preview {
    width: 100%;
  }
}
.poll-breakdown.modal {
  .modal-tabs {
    justify-content: flex-end;
  }
}

.poll-breakdown-sidebar {
  background: var(--primary-very-low);
  box-sizing: border-box;
  padding: 1rem;
}

.poll-breakdown-title {
  font-weight: 600;
  margin: 0;
  text-align: center;
}

.poll-breakdown-total-votes {
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 0.5rem;
  text-align: center;
}

.poll-breakdown-options {
  display: grid;
  list-style: none;
  margin: 1.5rem 0 0 0;
}

.poll-breakdown-option {
  align-items: center;
  border-radius: 5px;
  column-gap: 0.66rem;
  cursor: default;
  display: grid;
  grid-template-columns: 2.5rem 1fr;
  row-gap: 0.1rem;
  justify-content: start;
  padding: 0.5rem 1rem;
}

.poll-breakdown-option-color {
  align-self: end;
  border: 1px solid rgba(0, 0, 0, 0.15);
  grid-column: 1;
  height: 0.6rem;
  justify-self: center;
  width: 1.2rem;
}

.poll-breakdown-option-count {
  align-self: start;
  font-size: 0.9rem;
  grid-column: 1;
  justify-self: center;
}

.poll-breakdown-option-text {
  grid-column: 2;
  grid-row: 1/3;
}

.poll-breakdown-body {
  box-sizing: border-box;
  padding: 1rem 2rem;
}

.poll-breakdown-body-header {
  align-items: center;
  border-bottom: 1px solid var(--primary-low);
  display: flex;
  flex: 0 0 auto;
  padding-bottom: 0.5rem;
}

.poll-breakdown-body-header-label {
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0;
}

.poll-breakdown-dropdown {
  margin-left: 1rem;
}

.poll-breakdown-charts {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(33.3%, 0.33fr));
}

.poll-breakdown-chart-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 1rem;
  position: relative;
}

.poll-breakdown-chart-label {
  display: block;
  text-align: center;
}
